import * as React from "react"
import { Link } from "gatsby"

const FAQCard = () => {
  return (
    <>
      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            What services do we offer?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p>
              We offer a wide range of services; we buy
              <Link
                className="text-accent"
                to={
                  "/selling-your-jewelry-locally-and-beyond-with-sell-us-your-jewelry-com/"
                }
              >
                {" "}
                outright{" "}
              </Link>
              followed by immediate payment, we
              <Link
                className="text-accent"
                to={"/the-benefits-of-consigning-luxury-jewelry/"}
              >
                {" "}
                consign{" "}
              </Link>{" "}
              and{" "}
              <Link className="text-accent" to={"/trade-in-your-jewelry/"}>
                {" "}
                trade{" "}
              </Link>
              jewelry, luxury watches, branded designer jewelry, gold and
              platinum jewelry, gold coins, diamonds, sterling silver flatware
              and hollowware. We are a world leading company; buy, sell, trade,
              consign and restore luxury watches and jewelry.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How do I sell my jewelry?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              At SellUsYourJewelry.com you have two options to choose from:
            </p>
            <ul
              className="list-disc"
              style={{ marginLeft: `2rem`, marginBottom: `0.5rem` }}
            >
              <li className="p-1">
                <Link className="text-accent" to={"/how-it-works/"}>
                  Sell online:
                </Link>{" "}
                You can sell from the comfort of your home with our easy online
                selling process. All you need to do to start is to
                <Link
                  className="text-accent"
                  to={"/what-are-you-looking-to-sell/"}
                >
                  {" "}
                  fill out the form.
                </Link>{" "}
                Once you submit the form, our team of experts will review it and
                get in contact with you. We will send you an
                <Link className="text-accent" to={"/ez-ship-box-request-form/"}>
                  {" "}
                  easy ship box
                </Link>{" "}
                for you to send in your item, make sure you insure it. Then we
                will inspect your item and make an offer. If you accept the
                offer you will receive your payment promptly either via check or
                wire payment.
              </li>
              <li className="p-1">
                <a
                  className="text-accent"
                  href="https://maps.app.goo.gl/A3XgDm59Jk4R6kZcA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  In person:
                </a>{" "}
                If you happen to be in Miami and you would like an in- person
                selling experience visit our Showroom located at 9595 Harding
                Ave, Surfside/Bal Harbour Fl 33154. We are open 6 days a week.
                You can simply walk-in, no appointment necessary. We have a team
                of experts ready to assist you.
              </li>
            </ul>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How much is my watch worth?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              At SellUsYourJewelry.com, determining what any pre-owned luxury
              watch is currently worth requires immense professional expertise
              backed by real-time market data analytics. That’s exactly what our
              veteran team of specialists leverage to provide highly accurate
              quotes.
            </p>
            <p className="mb-2">
              For over 43 years, we have perfected a customized valuation model
              that accounts for tiny nuances and condition variances across
              thousands of watches that impact resale pricing daily.
            </p>
            <p className="mb-2">
              Here’s what we evaluate to provide quotes that truly reflect your
              watch’s fair market value:
            </p>
            <ul
              className="list-disc"
              style={{ marginLeft: `2rem`, marginBottom: `0.5rem` }}
            >
              <li className="p-1">
                Brand From renowned houses like Rolex and Patek Philippe down to
                boutique brands, we analyze current demand metrics.
              </li>
              <li className="p-1">
                Model Specifics Case dimensions, movement complications,
                intended use (dress, sport, leather vs steel band etc.) matter
                for valuations.
              </li>
              <li className="p-1">
                Production Year Vintage, modern or limited edition - age adds
                character and value.
              </li>
              <li className="p-1">
                Market Availability Whether still in production or rare matters,
                we track inventory data.
              </li>
              <li className="p-1">
                Condition We examine functionality, scratches, dents, chips –
                exterior and mechanical state all factor in.
              </li>
              <li className="p-1">
                Service & Authentication Records Documentation of watchmaker
                servicing and proof of authenticity certifications can enhance
                value.
              </li>
              <li className="p-1">
                Accessories Having box, manuals, warranty docs etc. boosts worth
                so we note what’s present.
              </li>
              <li className="p-1">
                Customizations, Engravings, aftermarket mods, non-factory gems
                impact pricing.
              </li>
            </ul>
            <p className="mb-2">
              As you can see, meticulous attention to all visual, mechanical and
              historical details goes into our quotes. We leave no stone
              unturned, evaluating well beyond just basics.
            </p>
            <p className="mb-2">
              The result? Accurately appraised offers reflecting true resale
              value. Discover what your watch is worth. Our specialists eagerly
              await assessing your timepiece!
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How to get a FREE watch quote?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              Visit{" "}
              <Link className="text-accent" to={"/what-is-my-watch-worth/"}>
                https://sellusyourjewelry.com/what-is-my-watch-worth/
              </Link>
            </p>
            <p className="mb-2">
              Complete the online Watch Quote form detailing information on your
              watch
            </p>
            <p className="mb-2">
              Upload clear photos at multiple angles and of any papers, box,
              accessories, damage etc. This visual information allows an
              accurate value assessment by our buying team.
            </p>
            <p className="mb-2">
              Within 48 hours, you’ll receive a pricing report.
            </p>
            <p className="mb-2">
              If agreeable, We'll send out a pre-packed shipping box as well as
              comprehensive instructions on packaging your watch safely for
              transit.
            </p>
            <p className="mb-2">
              Find out what your watch is worth from renowned industry experts.
              Our easy process delivers real-time value estimates with no hassle
              or risk to support your selling decision. Discover the potential
              value hiding in your watch drawer or safe today!
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How to get a FREE jewelry quote?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              At SellUsYourJewelry.com, determining the current value of your
              unwanted jewelry is free and simple. We provide quick preliminary
              quotes based on details you provide, with no cost or obligation to
              move forward.
            </p>
            <p className="mb-2">Follow These Steps:</p>
            <p className="mb-2">
              Visit{" "}
              <Link className="text-accent" to={"/what-is-my-jewelry-worth/"}>
                https://sellusyourjewelry.com/what-is-my-jewelry-worth/
              </Link>
            </p>
            <ul
              className="list-disc"
              style={{ marginLeft: `2rem`, marginBottom: `0.5rem` }}
            >
              <li className="p-1">
                Fill out the online form providing as many details as possible
                about your jewelry including:
              </li>
              <li className="p-1">Item type, brand, approximate age</li>
              <li className="p-1">Gemstone and metal types, karats</li>
              <li className="p-1">Any certifications, appraisal documents</li>
              <li className="p-1">Overall wear and condition</li>
              <li className="p-1">Photo uploads</li>
              <li className="p-1">
                Click submit. Our buying experts carefully review each
                submission.
              </li>
            </ul>
            <p className="mb-2">
              Within 48 hours, you will receive a comprehensive jewelry value
              estimate based on current resale market rates and all provided
              information about your item.
            </p>
            <p className="mb-2">
              There is no cost or obligation attached to getting this initial
              quote. If agreeable, you can choose to send us your jewelry for
              final inspection and payout.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How much is my jewelry worth?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              Determining the value of your jewelry entails several
              considerations, such as:
            </p>
            <ul
              className="list-disc"
              style={{ marginLeft: `2rem`, marginBottom: `0.5rem` }}
            >
              <li className="p-1">
                <b>Metal Type.</b> Precious metals like gold, silver, and
                platinum and their purity and weight have innate value.
              </li>
              <li className="p-1">
                <b>Gemstones.</b> The quality and size of your jewelry’s
                gemstones impact the value. Precious gemstones like diamonds,
                rubies, sapphires, and emeralds typically add value, especially
                if they have good clarity, color, and cut.
              </li>
              <li className="p-1">
                <b>Designer or Brand.</b> Jewelry from well-known designers or
                brands usually would have higher built-in value. We pay premiums
                for
              </li>
              <li className="p-1">
                <b>Condition.</b> Well-maintained pieces free from damage and
                wear usually have a higher value.
              </li>
              <li className="p-1">
                <b>Market Demand.</b> The demand for certain types of jewelry in
                the market influences its value.
              </li>
              <li className="p-1">
                <b>Current Market Prices.</b> Precious metal and gemstone prices
                can fluctuate, affecting the overall value of your jewelry.
              </li>
              <li className="p-1">
                <b>Provenance or History.</b> Jewelry with a distinguished
                history or provenance, such as if a famous person owned the
                piece or has a documented history, may have added value.
              </li>
              <li className="p-1">
                <b>Documentation.</b> Certificates of authenticity, Lab
                Certificates like GIA Certificates, professional appraisals and
                receipts can provide additional information about your jewelry's
                worth and may enhance the price you will get.
              </li>
              <li className="p-1">
                <b>Original packaging</b> like original Boxes that came with
                your jewelry or watch increase the value of your item.
              </li>
              <li className="p-1">
                <b>Vintage or Antique Status.</b> Vintage or antique jewelry may
                have increased value due to its rarity and historical
                significance.
              </li>
              <li className="p-1">
                <b>Collectibility.</b> This refers to the value and desirability
                of your jewelry among collectors. The collectibility is
                subjective and is influenced by factors that make it stand out
                to those who collect unique and valuable jewelry.
              </li>
            </ul>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            What do we buy?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              At SellUsYourJewelry.com, we buy fine signed and estate jewelry
              and branded designer jewelry . Below is the most common jewelry
              and items we Buy:
            </p>
            <ul
              className="list-disc"
              style={{ marginLeft: `2rem`, marginBottom: `0.5rem` }}
            >
              <li className="p-1">
                <u>Fine gold & platinum jewelry with and without gemstones:</u>{" "}
                We buy 14k, 18k and 24k gold jewelry including necklaces,
                earrings, rings, bracelets, bangles,brooches, pins, and
                cufflinks. We buy jewelry with precious and semi- precious
                stones, diamonds, pearls, rubies, sapphires, emeralds, oriental
                catseye, and alexandrites, Amethysts Aquamarine, Opal, Amber,
                Turquoise.
              </li>
              <li className="p-1">
                <u>Branded Designer Jewelry:</u> We Buy branded signed and
                designer jewelry like Bvlgari, Cartier, Tiffany & Co., David
                Yurman, John Hardy, Roberto Coin, Van Cleef & Arpels, Channel,
                Chopard, Chimento, David Webb, Louis Vuitton and other designer
                jewelry
              </li>
              <li className="p-1">
                <u>Luxury branded watches:</u> We Buy Men watches and women
                watches from Luxury brands like Rolex, Patek Philippe, Cartier,
                Bvlgari, Tiffany & Co., Omega, Chopard, Jacob & Co,
                Jaeger-LeCoultre, Roger Dubouis, Frank Muller, IWC, Panerai,
                Ulysee Nardin, Hublot, Audemars Piguet, Breitling, David Yurman,
                Chopard, Van cleef & Arpels, Breguet, Piaget, Vancheron
                constantin, Hublot and many more!
              </li>
              <li className="p-1">
                <u>Diamonds:</u> We buy all varieties of diamonds. Certified
                diamonds from grading authorities like GIA and AGS. We also buy
                Non-certified diamonds. Our experts value any diamond based on
                factors like carat, cut, clarity and color.
              </li>
              <li className="p-1">
                <u>Gemstones:</u> In addition to diamonds, we also purchase
                other precious gemstones like Rubies, Emeralds, Sapphires,
                Tourmalines. We buy both certified gemstones (AGL and other
                grading labs) as well as non-certified stones. Our gemologists
                can appraise the type, quality, and market value of any colored
                gemstone.
              </li>
              <li className="p-1">
                <u>Sterling Silver:</u> We buy flatware Sets, tea sets, kettles
                as well as silver, gold and platinum bullion, coins and bars.
              </li>
            </ul>
            <p className="mb-2">
              <Link className="text-accent" to={"/brands-we-buy/"}>
                See our Full list of specific brands we buy for Jewelry and
                Watches.
              </Link>
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            Why sell to “ Sell Us Your Jewelry.com “?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              <u>
                <Link className="text-accent" to={"/about-us/"}>
                  Sell Us Your Jewelry.com
                </Link>
              </u>{" "}
              - The buying division of Gray & Sons provides the best of both
              worlds; a convenience of an online platform with worldwide reach
              and physical location, a showroom of a trusted jeweler with over
              43 years of experience in buying, consigning, trading and
              repairing of luxury jewelry and watches. We have a professional
              team of jewelry and watch experts, GIA certified gemologists &
              master watchmakers. We are also nationally recognized as a trusted
              jewelry, watch, and diamond buyer. Our selling process is easy and
              fast whether you do it online or in-person, you will get the
              highest offer for your items. Come See Us Last!
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            What if my jewelry is broken or has missing parts?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              We also buy jewelry that is in less than perfect condition or
              maybe beyond repair, mismatched sets or if it is outdated. We may
              buy it for scrap gold based on weight.{" "}
              <Link className="text-accent" to={"/what-is-my-jewelry-worth/"}>
                {" "}
                Just fill out our free-quote form
              </Link>{" "}
              to find out if your broken or missing parts item can be sold.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            What documents do I need to sell my jewelry, watches or diamonds?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              You do not need any documents to sell your jewelry or watches.
              However, you can fetch a higher price for your piece if you have
              the original box, receipt, or authentication certificate.
            </p>
            <p className="mb-2">
              We buy Diamonds including Certified Diamonds which are Lab graded,
              however it is not necessary to have a GIA certificate to sell your
              diamond or diamond jewelry. Our in- house experts will provide an
              opinion and assistance in case obtaining a GIA certificate is
              beneficial.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            Is my jewelry safe with your company?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              We are fully Insured and Bonded, once your items are in our
              possession they are fully insured. You can trust that our experts
              will handle and evaluate your valuable jewelry with the utmost
              care.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How fast will I get paid?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              As soon as you accept our offer and we conduct a full inspection
              of your watch or jewelry by our experts and masters watchmakers,
              we provide immediate payment either via check or wire transfer
              that usually takes 48 hours. If you are selling your item
              in-person in our showroom, you will receive your payment
              immediately at the time of completing the transaction.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            Do you price match?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              At SellUsYourJewelry, we strive to beat other offers. Our motto is
              come see us last for the highest offer. We pride ourselves not
              just in providing the best customer service but we make sure that
              you leave satisfied with your offer.
            </p>
            <p className="mb-2">
              <Link
                className="text-accent"
                to={
                  "/ignacio-s-exceptional-experience-with-sell-us-your-jewelry-com/"
                }
              >
                Check out this customer testimonial who left so satisfied with
                his offer that he came back a second time to experience our
                services.
              </Link>
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How do I contact SellUsYourJewelry about any questions of my watch
            or jewelry?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              At SellusYourJewelry.com, we strive to provide exceptional
              service, top-value quotes, and a smooth selling process for all
              our customers. Our team of experts is here to address any
              questions you may have.Please feel free to reach out and connect
              with us via:
            </p>
            <p className="mb-2">
              Phone
              <br />
              Speak directly with our friendly customer service representatives.
            </p>
            <p className="mb-2">
              Telephone:{" "}
              <a className="text-accent" href="tel:+13057706955">
                {" "}
                (305) 770-6955
              </a>
            </p>
            <p className="mb-2">
              Facsimile:{" "}
              <a className="text-accent" href="tel:+13058659666">
                {" "}
                (305) 865-9666
              </a>
            </p>
            <p className="mb-2">
              Email:{" "}
              <a className="text-accent" href="mailto:sell@grayandsons.com">
                sell@grayandsons.com
              </a>
            </p>
            <p className="mb-2">
              9595 Harding Ave, Surfside, FL 33154 <br /> Located conveniently
              across from the Bal Harbour Shops.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How do I ship my watch or jewelry to you?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              You'll find our shipping process is simple and convenient. When
              you accept our formal purchase offer, we'll send out a pre-packed
              shipping box as well as comprehensive instructions on packaging
              your jewelry or watch safely for transit. Make sure to get
              insurance for your item.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How do I pack my watch or jewelry to you?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              Packing and shipping your items to us is simple thanks to the
              materials we supply. When you accept our formal purchase offer, we
              will send out a specially designed Easy Ship Box that contains:
            </p>
            <p className="mb-2">
              Packaging instructions that detail how to carefully wrap and
              protect your watch or jewelry for transit
            </p>
            <p className="mb-2">
              Bubble wrap and padding to place around your items
            </p>
            <p className="mb-2">
              A sturdy outer box suitable for shipping to keep contents secure
            </p>
            <p className="mb-2">
              All you need to do is follow the provided packaging guidelines,
              seal up the box with tape, affix the shipping label, and drop off
              your package . The Easy Ship Box streamlines the entire
              fulfillment process for safely getting your valuables to our
              jewelry and watch buying center.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            Which watches sell best?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              <b>Pre-owned Rolex:</b> By far one of the most desirable luxury
              watch brands. Especially popular models include the Rolex
              Submariner, Rolex Daytona, Rolex GMT-Master, and Rolex Datejust.
              Stainless steel sports Rolex watches typically demand the highest
              resale values.
            </p>
            <p className="mb-2">
              <b>Pre-owned Patek Philippe:</b> Top-tier brand renowned for high
              precision. Complicated models like Patek Philippe perpetual
              calendar chronographs and Grand Complications command very strong
              resale prices. Simple Calatrava models also hold value
              beautifully.
            </p>
            <p className="mb-2">
              <b>Pre-owned Audemars Piguet:</b> Known for innovations and
              durability. Vintage Audemars Piguet Royal Oak models from the
              1970s and complex Offshore watches with tourbillons or minute
              repeaters are excellent sellers.
            </p>
            <p className="mb-2">
              <b>Pre-owned Omega:</b> Versatile Swiss-made watches covering
              numerous styles at great value points like the Omega Seamaster,
              Speedmaster, and Constellation - all of which enjoy fantastic
              resale demand. Vintage Omega tends to sell very well among
              collectors also.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How do I find pre-owned jewelry buyers near me to sell my Rolex or
            other luxury watches?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              To find jewelry buyers near you to sell your Rolex or other luxury
              watch, you can search online directories or platforms that
              specialize in buying and selling jewelry. Look for reputable
              buyers in your area and contact them through their provided
              contact information to inquire about selling. Sell Us Your
              Jewelry, the buying platform for Gray and Sons Jewelers is a first
              choice for thousands of Rolex, luxury watches and jewelry sellers
              meeting and exceeding all customer expectations.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How can I sell my Rolex or watch near me?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              Request a Free Quote Visit our website and complete the online
              form with details about your watch including the model, year,
              serial number, any papers, and overall condition. Upload clear
              photos for accurate assessment.
            </p>
            <p className="mb-2">
              Receive and Review Initial Offer Within 24-48 hours of submitting
              your watch details, our specialists will email you a formal quote
              based on fair market value.
            </p>
            <p className="mb-2">
              Ship Your Watch Safely If agreeable, accept the offer online. We
              will send labels, box, and packing materials.Make sure to insure
              your own package
            </p>
            <p className="mb-2">
              Upon receipt of your watch, our authentication experts verify all
              details. We then finalize the transaction seamlessly including
              prompt payment via check, wire, direct deposit or other preferred
              payment method.
            </p>
            <p className="mb-2">
              It's truly that simple. We handle every shipping and logistics
              detail for your convenience. With decades of experience buying
              Pre-owned Rolex and other luxury timepieces, we ease the entire
              process while delivering exceptional service and the best possible
              price for your watch.
            </p>
            <p className="mb-2">
              Reach out if you have any other questions about how to sell a
              Rolex near you! Our experts are here and happy to help.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How do I choose the right jewelry buyer?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              Choosing the right jewelry buyer is important for a fair and
              smooth selling experience. Consider factors such as their
              reputation, customer reviews, expertise in evaluating jewelry, and
              transparency in the selling process. It's also beneficial to
              compare offers from multiple buyers to get the best possible price
              for your jewelry. Sell US Your Jewelry strives to beat all other
              offers. COME SEE US LAST !
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            Where can I sell my watch?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              You can sell your watch to local jewelry stores, luxury watch
              dealers, online marketplaces, or through classified ads. Each
              option has its pros and cons, so consider factors like
              convenience, speed of sale, and the price you can get for your
              watch.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            Can I sell my jewelry near me?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              Yes, you can sell your jewelry either online from the comfort of
              your home or by visiting our showroom in person. Our showroom is
              located at 9595 Harding Ave, Miami beach/ Bal Harbour, Fl, 33154,
              No appointment necessary
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            Where can I sell my watch near me?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-3">
              We provide both online and in-person watch buying options for your
              convenience:
            </p>
            <p className="mb-3">
              <b>Online Nationwide</b>
              <br />
              Our website allows you to securely sell your watch from anywhere
              in the country by shipping it to us . We make the entire process
              smooth, starting with getting an initial quote online in under 48
              hours followed by shipping materials for you to safely send us
              your item.
            </p>
            <p className="mb-2">
              <b>In-Person Locally</b>
              <br />
              For those located in South Florida, you also have the option to
              visit our showroom located at:
            </p>
            <p className="mb-2 italic">
              9595 Harding Ave Miami Beach/Bal Harbour, FL 33154 (Located
              conveniently across from the Bal Harbour Shops outdoor mall)
            </p>
            <p className="mb-2">
              No appointment is necessary. You can stop by our store during
              business hours with your watch to meet our buying specialist
              in-person. They will examine your watch on-site and provide a
              competitive offer. If agreeable, you walk out with cash payment on
              the spot.
            </p>
          </div>
        </div>
      </details>

      <details
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
        className="group grid grid-cols-1 gap-4 p-3 md:grid-cols-2 border-t-gray-100 border-t-2"
      >
        <summary
          itemProp="name"
          className="flex justify-between items-center font-medium cursor-pointer list-none"
        >
          <span className="group-hover:text-accent group-focus:text-accent col-span-1 md:text-2xl sm:text-xl text-lg font-semibold md:col-span-2">
            How can I find a reliable watch buyer?
          </span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className="text-justify p-1 mt-3 group-open:animate-fadeIn h-full col-span-1 md:col-span-2"
        >
          <div itemProp="text">
            <p className="mb-2">
              With over 43 years of experience in buying luxury pre-owned
              watches, SellUsYourJewelry.com has proven our reliability to
              thousands of customers who have entrusted us with their Rolex,
              Omega, Cartier and other fine watches.
            </p>
            <p className="mb-2">
              <b>
                What makes us a top choice among both first-time and veteran
                watch sellers?
              </b>
            </p>
            <p className="mb-2">
              For one, our credentialed in-house experts including gemologists
              and master watchmakers thoroughly inspect each timepiece to derive
              accurate valuations across all watch brands and models. We stay
              deeply knowledgeable on market values and trends.
            </p>
            <p className="mb-2">
              We also pride ourselves on running a transparent buying process
              designed for the customer’s best interest and convenience. From
              our user-friendly 24-48 hour online quote system to shipping and
              secured payments, we simplify selling your watch.
            </p>
            <p className="mb-2">
              Additionally, as a family-owned Florida business in operation
              since 1980, we stand behind every transaction and guarantee
              clients receive excellent service plus the highest possible payout
              price for their watches.
            </p>
          </div>
        </div>
      </details>
    </>
  )
}

export default FAQCard
