import * as React from "react"
import Carousel from "../Carousel"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ImageSizes from "../../helpers/ImageSizes"
import TailwindScreens from "../../helpers/tailwind"
import { Link } from "gatsby"

const prepareImage = (image, items = 4) => {
  image = image.replace("/o/", "/r/")
  TailwindScreens

  if (items >= 4 || Number.isNaN(items) || items < 1) {
    return getImage({
      gatsbyImageData: {
        layout: "constrained",
        backgroundColor: "#FFFFFF",
        images: {
          fallback: {
            src: image.replace(".jpg", "-w360.jpg"),
            srcSet: `${image.replace(".jpg", "-w360.jpg")} 1x, ${image.replace(
              ".jpg",
              "-w360.jpg"
            )} 2x, ${image.replace(".jpg", "-w640.jpg")} 3x`,
          },
          sources: [
            {
              srcSet: `${image.replace(".jpg", "-w360.avif")} 1x, ${image.replace(
                ".jpg",
                "-w360.avif"
              )} 2x, ${image.replace(".jpg", "-w640.avif")} 3x`,
              type: "image/avif",
            },
            {
              srcSet: `${image.replace(".jpg", "-w360.webp")} 1x, ${image.replace(
                ".jpg",
                "-w360.webp"
              )} 2x, ${image.replace(".jpg", "-w640.webp")} 3x`,
              type: "image/webp",
            },
          ],
        },
        width: 180,
        height: 180,
      },
    })
  }
  // return getImage({
  //   gatsbyImageData: {
  //     layout: "constrained",
  //     backgroundColor: "#FFFFFF",
  //     images: {
  //       fallback: {
  //         src: image.replace(".jpg", "-w360.jpg"),
  //         srcSet: `${image.replace(".jpg", "-w360.jpg")} 1x, ${image.replace(
  //           ".jpg",
  //           "-w360.jpg"
  //         )} 2x, ${image.replace(".jpg", "-w640.jpg")} 3x`,
  //       },
  //       sources: [
  //         {
  //           srcSet: `${image.replace(".jpg", "-w360.avif")} 1x, ${image.replace(
  //             ".jpg",
  //             "-w360.avif"
  //           )} 2x, ${image.replace(".jpg", "-w640.avif")} 3x`,
  //           type: "image/avif",
  //         },
  //         {
  //           srcSet: `${image.replace(".jpg", "-w360.webp")} 1x, ${image.replace(
  //             ".jpg",
  //             "-w360.webp"
  //           )} 2x, ${image.replace(".jpg", "-w640.webp")} 3x`,
  //           type: "image/webp",
  //         },
  //       ],
  //     },
  //     width: (4 * 180) / items,
  //     height: (4 * 180) / items,
  //   },
  // })
  let width = (4 * 180) / items
  let height = (4 * 180) / items

  let sizes = `${TailwindScreens["lg"]} 50vw, ${TailwindScreens["xl"]} ${Math.round(
    57.25 / items,
    4
  )}em, 100vw`
  let srcsetWebp = ""
  let srcsetAvif = ""
  let srcset = ""
  let normal = { url: image }
  for (const size of ImageSizes) {
    srcset += normal.url.replace(/[.]jpg+/, `-w${size}.jpg`) + ` ${size}w,\n`
    srcsetWebp += normal.url.replace(/[.]jpg+/, `-w${size}.webp`) + ` ${size}w,\n`
    srcsetAvif += normal.url.replace(/[.]jpg+/, `-w${size}.avif`) + ` ${size}w,\n`
  }
  const fallbackUrl = normal.url.replace(/[.]jpg+/, "-w360.jpg")
  return getImage({
    gatsbyImageData: {
      layout: "constrained",
      backgroundColor: "#FFFFFF",
      images: {
        fallback: {
          src: fallbackUrl,
          srcSet: srcset,
          sizes: sizes,
        },
        sources: [
          {
            srcSet: srcsetAvif,
            type: "image/avif",
            sizes: sizes,
          },
          {
            srcSet: srcsetWebp,
            type: "image/webp",
            sizes: sizes,
          },
        ],
      },
      width: width,
      height: height,
    },
  })
}

const FeaturedProductsBlock = ({ products, title }) => {
  const items =
    (products || []).length >= 4 ? 4 : (products || []).length > 0 ? (products || []).length : 1
  return (
    <div className={"w-full "}>
      <h2 className="text-center text-3xl uppercase pb-4">{title}</h2>
      <Carousel
        slides={{ xs: 1, lg: 2, xl: items }}
        className={""}
        gap={8}
        infiniteLoop={(products || []).length > 4}
        layoutClassName={"p-4"}
      >
        {products.map(product => {
          return (
            <Link
              to={`/${product.url}`}
              rel={"noreferrer"}
              key={product.name}
              className={"group flex flex-col items-center gap-2 bg-white p-4 shadow-lg w-full"}
            >
              <div className={"flex justify-center w-full h-full aspect-square"}>
                <GatsbyImage
                  alt={product.name}
                  image={prepareImage(product.image, items)}
                  className={"w-full h-full aspect-square object-contain"}
                />
              </div>

              <div className={"translate-0 h-full transform px-4 duration-200"}>{product.name}</div>

              <div className={"mx-auto w-auto bg-accent p-4 py-2 text-white group-hover:bg-link"}>
                See Details
              </div>
            </Link>
          )
        })}
      </Carousel>
    </div>
  )
}

FeaturedProductsBlock.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      image: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
}

FeaturedProductsBlock.defaultProps = {
  products: null,
  title: null,
}

export { prepareImage }
export default FeaturedProductsBlock
