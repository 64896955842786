import * as React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import { IconRing } from "../icons/Icons"
import Testimonials from "../common/Testimonials"
import YouTube from "../common/YouTube"
import Map from "../common/Map"
import HeroImage from "../HeroImage"
import HowItWorks from "../common/HowItWorks"
import FAQs from "../common/FAQs"
import { prepareImage } from "../products/FeaturedProductsSlider"

const IndexBelowTheFold = () => {
  const { watches, jewelry, blogs } = useStaticQuery(
    graphql`
      query CombinedHomepageQuery {
        blogs: allStrapiPage(
          filter: { press_release: { eq: true } }
          sort: { order: DESC, fields: updated_at }
          limit: 3
        ) {
          nodes {
            title
            meta_title
            meta_description
            slug
            hero_image_url
            hero_image {
              url
            }
            created_at
            updated_at
          }
        }
        watches: allStrapiPage(
          filter: { tag: { watches: { eq: true } } }
          sort: { fields: tag___brand }
        ) {
          nodes {
            slug
            tag {
              brand
            }
          }
        }
        jewelry: allStrapiPage(
          filter: { tag: { jewelry: { eq: true } } }
          sort: { fields: tag___brand }
        ) {
          nodes {
            slug
            tag {
              brand
            }
          }
        }
      }
    `
  )


  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
  const watchesByLetter = {}
  const jewelryByLetter = {}
  watches.nodes.forEach(watch => {
    const letter = watch.tag.brand[0].toUpperCase()
    if (!watchesByLetter[letter]) {
      watchesByLetter[letter] = []
    }
    watchesByLetter[letter].push(watch)
  })
  jewelry.nodes.forEach(jewelry => {
    const letter = jewelry.tag.brand[0].toUpperCase()
    if (!jewelryByLetter[letter]) {
      jewelryByLetter[letter] = []
    }
    jewelryByLetter[letter].push(jewelry)
  })

  return (
    <>
      <hr className="w-10/12 mx-auto mt-5" />
      <section className="m-auto ">
        <h2 className="p-10 text-center text-2xl uppercase w-10/12 max-w-[1366px] mx-auto">
          Why Sell Us Your Jewelry?
        </h2>
        <p className="max-w-[800px] mx-auto leading-relaxed w-10/12 ">
          If you’re looking for jewelry sellers established in their niche, Sell Us Your Jewelry is
          a trusted name. It began to distinguish itself from other dealers through its meticulous
          restoration of watches, encouraging customer service, and ability to reach customers
          conveniently in Dallas, Boston, Chicago, Philadelphia, Los Angeles, Washington DC,
          Cleveland Ohio, New York, Las Vegas, California, Hartford Connecticut, and the rest of the
          United States. <br />
          <br />
          Other potential buyers, like pawn shops and other platforms, could not match what Sell Us
          Your Jewelry does.
          <br />
          <br />
          <strong>What makes us different:</strong>
          <div className="ml-10">
            <p className="list-item">40+ Years of Experience</p>
            <p className="list-item">Best Prices</p>
            <p className="list-item">Fast & Secure Process</p>
            <p className="list-item">Convenient & Discreet</p>
            <p className="list-item">Exceptional Customer Service</p>
          </div>
          <br />
          Are you planning to sell jewelry online? Sell Us Your Jewelry is the quickest and most
          convenient platform! Sell luxury jewelry online today, we are recognized not only in South
          Florida but worldwide as the #1 <a href="/luxury-jewelry-buyer/">jewelry buyer</a>, Sell
          Us Your Jewelry.
        </p>
        <hr className="w-10/12 mx-auto mt-5" />
        <section className="m-auto ">
          <h2 className="p-10 text-center text-3xl uppercase w-10/12 max-w-[1366px] mx-auto">
            We Buy All Types of Jewelry
          </h2>
          <p className="max-w-[1024px] mx-auto text-center leading-relaxed w-10/12 ">
            From diamond engagement rings to vintage brooches, we buy a wide range of jewelry
            pieces, including brands like Tiffany & Co., Cartier, Van Cleef & Arpels, and David
            Yurman. Get a free, no-obligation quote today and see how much your piece of jewelry is
            worth.
          </p>
        </section>
        <div className="flex w-full lg:w-10/12 lg:max-w-[1366px] mx-auto flex-col items-center justify-center h-full lg:flex-row  my-5">
          <div className="w-full aspect-video lg:rounded-xl overflow-hidden m-5">
            <YouTube
              src={"https://www.youtube.com/embed/eqmFkSMB7co?si=iNrNNMXQTQK15P_V?rel=0&mute=0"}
              title={"How To Sell Your Jewelry and Watches"}
              className={"aspect-video w-full "}
            />
          </div>
        </div>
      </section>
      <section className="py-10 flex flex-col lg:flex-row w-full  ">
        <div className="flex flex-col items-center justify-center lg:w-1/2 container mx-auto relative group py-10">
          <div className="bg-yellow-100 h-full w-full skew-x-[20deg] absolute top-0 -left-[35vw] -z-[1] group-hover:-left-[30vw] duration-300 ease-out"></div>
          <div className=" w-10/12 max-w-max text-center flex flex-col md:flex-row lg:flex-col xl:flex-row items-center justify-center mx-auto bg-white p-5 shadow-lg relative">
            <div className="aspect-square max-w-[350px] w-full mx-auto">
              <StaticImage
                loading="lazy"
                placeholder="blurred"
                src={"../../images/sell-watch-viktoria.jpg"}
                alt={"Viktoria Peshkur Profile Picture"}
                className={"object-contain w-full "}
                sizes="(max-width: 768px) 90vw, 420px"
              />
              <p className="text-center text-xl font-bold uppercase py-2">Viktoria Peshkur</p>
            </div>
            <div
              className={
                " h-full my-5 text-center w-full whitespace-pre-line p-5 max-w-[350px] flex flex-col justify-center items-center relative"
              }
            >
              Call ME NOW!{"\n"}
              <a href="tel:+13057706955" className="whitespace-nowrap">
                305-770-6955
              </a>
              {"\n"}
              9am – 9pm (ET){"\n"}- - - - -{"\n"}
              After 9 pm text your contact information and pictures of your item to{" "}
              <a href="tel:+17862664763" className="whitespace-nowrap">
                786-266-4763
              </a>
              <p className="absolute bottom-0">For a fast response simply</p>
              <button
                className={
                  " text-white font-bold bg-[#816230] hover:bg-accentMd absolute -bottom-14 md:-bottom-[5.5rem] lg:-bottom-14 xl:-bottom-10 left-[50%] -translate-x-[50%] uppercase px-5 py-2 rounded-full duration-300 max-w-max"
                }
                to="/what-are-you-looking-to-sell/"
              >
                CHAT NOW
              </button>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 aspect-video bg-blue-200 relative">
          <div className="aspect-video w-full md:w-[400px] bg-white border border-blue-200 p-5 md:absolute md:-top-5 md:left-0 lg:-left-10 z-10 shadow-lg flex items-center justify-center">
            <p className="p-5 whitespace-pre-line leading-normal">
              <b className="text-xl text-gray-600 uppercase">Showroom hours:</b>
              <br />
              9595 Harding Ave{"\n"}
              Surfside (Miami Beach) FL 33154{"\n"}
              <i className="text-sm">(Located across from Bal Harbour Shops){"\n"}</i>
              <b>Store Hours:</b>
              <br />
              Monday - Saturday: 10am to 5pm
              <br />
              <i className="text-sm">
                <nobr>(Open Sundays 11:00am - 5:00pm</nobr> <nobr>during the holiday season,</nobr>{" "}
                <nobr>from Thanksgiving through New Year{"'"}s Eve)</nobr>
              </i>
            </p>
          </div>
          <Map className={"w-full h-full aspect-square object-center "} />
        </div>
      </section>

      <section className="bg-yellow-50 py-20 w-full flex flex-col lg:flex-row relative lg:px-5">
        <div className=" w-full h-full max-w-max mx-auto lg:sticky top-5">
          <StaticImage
            src={"../../images/top-watch-buyer-img.png"}
            alt={"Rolex, Patek Philippe, GIA Diamond Ring and Studs Buyer"}
            className={"object-contain aspect-square  h-full"}
          />
        </div>
        <section className="w-full">
          <div className="grid xl:grid-cols-2 p-3 w-10/12 mx-auto max-w-[1000px]">
            <div className="col-span-2 border-t my-4 border-accent">
              <h2
                className={
                  "text-center text-accent max-w-max px-3 py-1 -translate-y-5 border bg-yellow-50 border-accent mx-auto"
                }
              >
                Watch Brands
              </h2>
              <div className={"columns-2 xl:columns-3 2xl:columns-4"}>
                {letters
                  .filter(letter => watchesByLetter[letter] && watchesByLetter[letter].length)
                  .map(letter => {
                    return (
                      <div key={letter} className={"break-inside-avoid-column"}>
                        <h3 className={"my-2"}>{letter}</h3>
                        <ul>
                          {watchesByLetter[letter] &&
                            watchesByLetter[letter].map(watch => {
                              return (
                                <li key={watch.slug} className="my-5 lg:my-2">
                                  <Link to={`/${watch.slug}/`}>{watch.tag.brand}</Link>
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className="col-span-2 border-t my-4 border-accent ">
              <h2
                className={
                  "text-center text-accent max-w-max px-3 py-1 -translate-y-5 border bg-yellow-50 mx-auto border-accent"
                }
              >
                Jewelry Brands
              </h2>
              <div className={"columns-2 lg:columns-3 xl:columns-4"}>
                {letters
                  .filter(letter => jewelryByLetter[letter] && jewelryByLetter[letter].length)
                  .map(letter => {
                    return (
                      <div key={letter} className={"break-inside-avoid-column"}>
                        <h3 className={"my-2"}>{letter}</h3>
                        <ul>
                          {jewelryByLetter[letter] &&
                            jewelryByLetter[letter].map(watch => {
                              return (
                                <li key={watch.slug} className="my-5 lg:my-2">
                                  <Link to={`/${watch.slug}/`}>{watch.tag.brand}</Link>
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                    )
                  })}
              </div>
              <div className="col-span-2 border-t my-10 border-accent">
                <h2
                  className={
                    "text-center text-accent max-w-max px-3 py-1 -translate-y-5 border bg-yellow-50 border-accent mx-auto"
                  }
                >
                  Other Luxury Goods
                </h2>
                <ul>
                  <li className="my-5 lg:my-2">
                    <Link to={"/sell-diamond-rings-now/"}>Diamonds</Link>
                  </li>
                  <li className="my-5 lg:my-2">
                    <Link to={"/sell-silver-florida/"}>Sterling Silver</Link>
                  </li>
                  <li className="my-5 lg:my-2">
                    <Link to={"/sell-my-pocket-watch/"}>Pocket Watches</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </section>

      <HowItWorks className={"w-10/12 mx-auto max-w-[1366px] xl:my-40"} />

      <hr className="my-10 w-10/12 mx-auto" />
      <FAQs className={"my-20"} />
      <section className="flex w-full flex-col justify-center bg-gradient-to-t from-gray-100 to-gray-300 py-10 align-middle visibility-auto">
        <div className="w-10/12 mx-auto max-w-[1366px]">
          <h2 className="px-10  py-10 text-center text-4xl uppercase lg:py-10">
            Questions? Talk To A Specialist
          </h2>
          <div className="flex w-full justify-center text-center  flex-col md:flex-row">
            <Link
              to="/about-us/"
              className="margin-auto hoverScale block  w-full items-center p-10"
            >
              <StaticImage
                src={"../../images/sell-watch-with-rich-finlinson.jpg"}
                alt={"Rich Finlinson Sell Your Jewelry Picture"}
                className="rounded-full"
                width={360}
              />
              <p>RICH</p>
            </Link>
            <Link
              to="/about-us/"
              className="margin-auto hoverScale block  w-full items-center p-10"
            >
              <StaticImage
                src={"../../images/sell-watch-viktoria.jpg"}
                alt={"Viktoria Peshkur Sell Your Jewelry Picture"}
                className="rounded-full"
                width={360}
              />
              <p>VIKTORIA</p>
            </Link>
          </div>
          <div className="flex flex-row justify-center">
            <a href="tel:+13057706955" className="btn-cta mx-5 w-[20rem] p-5">
              <IconRing /> &nbsp; 305&nbsp;770&nbsp;6955
            </a>
            <Link to="what-are-you-looking-to-sell/" className="btn-cta w-[20rem] p-5">
              SELL NOW
            </Link>
          </div>
          <p className="text-center mt-6"> Telephone 9am-6pm EST, Monday through Saturday.</p>
        </div>
      </section>

      <section className={"container mx-auto visibility-auto"}>
        <h2 className={"px-10  py-10 text-center text-4xl uppercase lg:py-10"}>
          Our recent reviews
        </h2>
        <Testimonials />
      </section>

      <h2 className="p-10 text-center text-3xl visibility-auto">In the press</h2>
      <div className={"container mx-auto flex gap-4"}>
        <section title={"Press Releases Posts list"} className={"flex w-full flex-wrap"}>
          {blogs.nodes.map(blog => {
            return (
              <article key={blog.slug} className={"block w-full p-4 md:w-1/2 lg:w-1/3"}>
                <Link to={"/" + blog.slug + "/"} className={"mx-auto block w-80 "}>
                  {blog.hero_image_url || blog.hero_image?.url ? (
                    <HeroImage
                      url={blog.hero_image_url || blog.hero_image.url}
                      alt={blog.meta_title}
                      className={"h-80 w-80 rounded-2xl object-contain"}
                    />
                  ) : (
                    <StaticImage
                      src={"../../images/top-watch-buyer-img.png"}
                      alt={blog.meta_title}
                      className={"h-80 w-80 rounded-2xl object-cover"}
                    />
                  )}
                </Link>
                <h2 className="mx-auto w-80 py-4 ">
                  <Link className="text-black" to={"/" + blog.slug + "/"}>
                    {blog.title}
                  </Link>
                </h2>
                <Link
                  className={"mx-auto block w-80 group text-black"}
                  aria-label={blog.title}
                  to={"/" + blog.slug + "/"}
                >
                  {blog.meta_description}
                  <span className="group-hover:tracking-wide group-hover:text-accentMd text-accent duration-200 ease-in-out pt-4 block">
                    Read More
                  </span>
                </Link>
              </article>
            )
          })}
        </section>
      </div>
      <Link to="/in-the-press/" className={"btn-cta p-6 block w-80 mx-auto"}>
        View all press releases
      </Link>

      <h2 className="p-10 text-center text-3xl visibility-auto">
        See us last! We will{" "}
        <Link to="/what-is-my-sterling-silver-worth/">offer the best price for the silverware</Link>
        , scrap sterling silver, or silver coins you want to sell.
      </h2>

      <section className="flex w-full flex-col justify-center pt-10 visibility-auto">
        <div className="flex w-full flex-col items-center justify-center ">
          <a href="tel:+13057706955" className="text-3xl font-bold text-accent hover:text-gray-500">
            <h3 className="px-10 text-center text-3xl uppercase">Call Us Now: 305 770 6955</h3>
          </a>
          <div className="w-full shadow-xl"> &nbsp;</div>
          <h2 className="pt-10 uppercase text-gray-800">
            The Purchasing and Consignment Division of Gray &amp; Sons
          </h2>
          <a href="https://www.grayandsons.com/catalog-request">
            <StaticImage
              src={"../../images/svg/gray-and-sons-logo.svg"}
              alt={"Gray and Sons Jewelers"}
            />
          </a>
          <div className="w-full py-8 text-center text-2xl font-black uppercase">
            BROWSE OUR DIGITAL CATALOG
          </div>
          <a
            href="https://www.grayandsons.com/catalog-request"
            className="flex w-full justify-center"
          >
            <StaticImage
              src={"../../images/gray-and-sons-request-catalog-banner.jpeg"}
              alt={"Gray and Sons Catalog Subscription"}
            />
          </a>
        </div>
      </section>
    </>
  )
}

export default IndexBelowTheFold
