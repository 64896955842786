import * as React from "react"
import FAQCard from "./FAQs-data"

const FAQs = ({ className }) => {

  return (
    <section itemScope itemType="https://schema.org/FAQPage" className={" " + className}>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 gap-x-10">
          <div className="text-center col-span-1 lg:col-span-2">
            <h2 className="font-bold text-5xl mt-5 tracking-tight">
              FAQ
            </h2>
            <p className="text-neutral-500 text-2xl mt-3 mb-2">
              Frequently Asked Questions
            </p>
          </div>
            <FAQCard/>
        </div>
      </div>
    </section>
  )
}

export default FAQs
